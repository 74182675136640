<template>
  <div
    class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
  >
    <div class="flex flex-col overflow-y-auto md:flex-row">
      <div class="flex items-center justify-center p-6 sm:p-12 w-full">
        <form class="w-full" @submit="signIn">
          <h1
            class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
          >
            Connexion
          </h1>
          <label for="email" class="block text-sm" data-children-count="1">
            <span class="text-gray-700 dark:text-gray-400">E-mail</span>
            <input
              id="email"
              class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              placeholder="nom@exemple.com"
              type="email"
              name="email"
              ref="email"
              v-model="email"
              autocomplete="email"
            />
          </label>
          <label
            for="password"
            class="block mt-4 text-sm"
            data-children-count="1"
          >
            <span class="text-gray-700 dark:text-gray-400">Mot de passe</span>
            <input
              id="password"
              class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              placeholder="***************"
              type="password"
              name="password"
              ref="password"
              v-model="password"
              autocomplete="password"
            />
          </label>

          <!-- You should use a button here, as the anchor is only used for the example  -->
          <a
            class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            v-on:click="signIn"
          >
            Connexion
          </a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/utils/auth";
import api from "@/utils/api/auth";

export default {
  data() {
    return {
      email: null,
      password: null
    };
  },
  methods: {
    /**
     * Sign in user and store token in cookie.
     */
    signIn: function() {
      // auto fill may not working as expected, so need to user rel to make sure that email and password variable changes
      this.email = this.$refs.email.value;
      this.password = this.$refs.password.value;

      api
        .signIn({
          email: this.$data.email,
          password: this.$data.password
        })
        .then(res => {
          // set token and redirect to home
          auth.setToken(res.data.data);
          this.$router.push("/");
        })
        .catch(e => alert("Une erreur est survenue. détails: " + e));
    },
    forgottenPassword: function() {
      auth.setToken("tojkkeeen");
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
</style>
