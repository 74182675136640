import { axiosUnAuth } from "@/utils/api/index";

/**
 * Sign in user.
 * @param body: Body containing email and password.
 */
const signIn = async body => {
  return axiosUnAuth().post("/auth/sign-in", body);
};

export default { signIn };
